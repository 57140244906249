import './skip-link-focus-fix';
import './scrolloverflow.min.js';
import fullpage from 'fullpage.js';
import lottie from 'lottie-web';
import AOS from 'aos';
import lity from 'lity';
import confetti from 'canvas-confetti';
import * as Player from "@vimeo/player/dist/player.js";

(function($) {
	$(document).ready(function() {

		$('body').addClass('no-scroll');

		AOS.init({ once: false, duration: 700, startEvent: 'AOSinit' });

		// var loadingScreen    = $('#loadingScreen'),
		//     loadingAnimation = $('#loadingAnimation'),
		//     loadingPath      = loadingAnimation.data('path');
			
		// var loadingAnimation = lottie.loadAnimation({
		// 	container: loadingAnimation[0],
		// 	renderer: 'svg',
		// 	loop: true,
		// 	autoplay: true,
		// 	path: loadingPath,
		// })

		var sectionTooltips = [];

		$('.section').each(function(){
			var tooltip = $(this).data('tooltip');
			sectionTooltips.push(tooltip);
		});

		var anim        = [],
		    animNumber  = $('.lottie-animation').length,
			animsLoaded = 0,
			fullPageInstance;

		if (animNumber > 0) {
			$('.lottie-animation').each(function(i){
				var index = $(this).data('index'),
					path  = $(this).data('path');
	
				anim[index] = lottie.loadAnimation({
					container: $(this)[0],
					renderer: 'svg',
					loop: true,
					autoplay: false,
					path: path,
				})
	
				anim[index].addEventListener('DOMLoaded', function(e) {
					animsLoaded++;
	
					if(animsLoaded == animNumber) {
	
						// $('#loadingScreen').fadeOut();
	
						$(document).trigger('AOSinit');
	
						// var lityContent = $('#popupContent');
						// if(lityContent) {
						// 	if(!$('body').hasClass('logged-in')) {
						// 		lity.options('template', '<div class="lity" role="dialog" aria-label="Dialog Window (Press escape to close)" tabindex="-1"><div class="lity-wrap" role="document"><div class="lity-loader" aria-hidden="true">Loading...</div><div class="lity-container"><div class="lity-content"></div></div></div></div>');
						// 	}
						// 	var popupForm = lity('#popupContent');
	
						// 	$(document).on('gform_confirmation_loaded', function(event, form_id, current_page){
						// 		popupForm.close();
						// 		lity.options('template', '<div class="lity" role="dialog" aria-label="Dialog Window (Press escape to close)" tabindex="-1"><div class="lity-wrap" role="document" data-lity-close><div class="lity-loader" aria-hidden="true">Loading...</div><div class="lity-container"><div class="lity-content"></div><button class="lity-close" type="button" aria-label="Close (Press escape to close)" data-lity-close>&times;</button></div></div></div>');
						// 	});
						// }
	
						$(document).ready( function() {
							if(!fullPageInstance) {
								fullPageInstance = new fullpage('#fullPage', {
									navigation: true,
									scrollOverflow: true,
									verticalCentered: false,
									scrollingSpeed: 700,
									navigationTooltips: sectionTooltips,
									onLeave: (index, nextIndex) => {
										$('.section [data-aos]').removeClass("aos-animate");
			
										$('.section .lottie-animation').each(function(i){
											var index = $(this).data('index');
											setTimeout(function(){
												anim[index].stop();
											}, 1000);
										});
			
										// if(nextIndex.item.id == 'confetti') {
										// 	setTimeout(function(){
										// 		confetti({
										// 			particleCount: 100,
										// 			spread: 70,
										// 			origin: { y: 0.6 }
										// 		});
										// 	}, 1000);
										// }
									},
									onSlideLeave: function(){
										$('.slide [data-aos]').removeClass("aos-animate");
			
										$('.slide .lottie-animation').each(function(i){
											var index = $(this).data('index');
											setTimeout(function(){
												anim[index].stop();
											}, 1000);
										});
									},
									afterSlideLoad: function(){
										$('.slide.active [data-aos]').addClass("aos-animate");
										
										$('.slide.active .lottie-animation').each(function(i){
											var index = $(this).data('index');
											setTimeout(function(){
												anim[index].play();
											}, 1000);
										});
									},
									afterLoad: function(){
										$('.section.active [data-aos]').addClass("aos-animate");
			
										$('.section.active .lottie-animation').each(function(i){
											var index = $(this).data('index');
											setTimeout(function(){
												anim[index].play();
											}, 1000);
										});
									},
								});
								}
							 $('body').removeClass('no-scroll');

						});
	
						// setTimeout(function(){
						// 	loadingAnimation.destroy();
						// }, 2000);
					}
				});
			});
		} else {
			$(document).trigger('AOSinit');

			$(document).ready( function() {
				if(!fullPageInstance) {
					fullPageInstance = new fullpage('#fullPage', {
						navigation: true,
						scrollOverflow: true,
						verticalCentered: false,
						scrollingSpeed: 700,
						navigationTooltips: sectionTooltips,
						onScrollOverflow: function( section, slide, position, direction){
							return;
						},
						onLeave: function(index, nextIndex){
							$('.section [data-aos]').removeClass("aos-animate");

							// $('.section .lottie-animation').each(function(i){
							// 	var index = $(this).data('index');
							// 	setTimeout(function(){
							// 		anim[index].stop();
							// 	}, 1000);
							// });

							// if(nextIndex.item.id == 'confetti') {
							// 	setTimeout(function(){
							// 		confetti({
							// 			particleCount: 100,
							// 			spread: 70,
							// 			origin: { y: 0.6 }
							// 		});
							// 	}, 1000);
							// }
						},
						onSlideLeave: function(){
							$('.slide [data-aos]').removeClass("aos-animate");

							// $('.slide .lottie-animation').each(function(i){
							// 	var index = $(this).data('index');
							// 	setTimeout(function(){
							// 		anim[index].stop();
							// 	}, 1000);
							// });
						},
						afterSlideLoad: function(){
							$('.slide.active [data-aos]').addClass("aos-animate");
							
							// $('.slide.active .lottie-animation').each(function(i){
							// 	var index = $(this).data('index');
							// 	setTimeout(function(){
							// 		anim[index].play();
							// 	}, 1000);
							// });
						},
						afterLoad: function(){
							$('.section.active [data-aos]').addClass("aos-animate");

							// $('.section.active .lottie-animation').each(function(i){
							// 	var index = $(this).data('index');
							// 	setTimeout(function(){
							// 		anim[index].play();
							// 	}, 1000);
							// });
						},
					});
					}
				 $('body').removeClass('no-scroll');
			});
		}

		


		var player;

		$('.step-block__video-container').on('click', function(){
			var play        = $(this).find('.step-block__video-play'),
			    video       = $(this).find('.step-block__video'),
			    overlay     = $(this).find('.step-block__video-overlay'),
			    overlayText = $(this).find('.step-block__overlay-text');

				if(!play.hasClass('playing')) {
					player = new Player(video[0]);
					player.play();
				} else {
					player.pause();
				}

				overlay.fadeOut();
				overlayText.fadeOut();
				video.fadeIn();

				player.on('play', function() {
					play.addClass('playing');
				});

				player.on('pause', function() {
					play.removeClass('playing');
				});
		});
		
		// $(document).on('gform_confirmation_loaded gform_post_render', function(event, form_id, current_page){
		// 	if(fullPageInstance) {
		// 		fullPageInstance.reBuild();
		// 	} 
		// });
	});

})(jQuery);